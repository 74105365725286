<template>
  <div class="pageContent">
    <searchHeader />
    <div class="productionDetails">
        <!-- <div id="qrcode" >
                     <qrcode-vue size="200" level="H" :value="codeValue"></qrcode-vue>
                
                </div> -->
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="proInfo">
            <div v-if="orderDetails.img !== '--'" class="proImage">
              <img :src="orderDetails.img" />
            </div>
            <div class="proContent">
              <p class="commodity">{{ orderDetails.commodityName }}</p>
              <div class="desc">
                <p>
                  <span class="item">
                    <span class="itemTitle">单价：</span>
                    <span class="itemText">{{ orderDetails.price }}</span>
                  </span>
                  <span class="item">
                    <span class="itemTitle">更新时间：</span>
                    <span class="itemText">{{ orderDetails.updateTime | DateFormate }}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">重量: </span>
                    <span class="itemText">{{ orderDetails.weight }}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">规格/牌号：</span>
                    <span class="itemText">{{ orderDetails.brand }}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">货物所在地:</span>
                    <span class="itemText">{{ orderDetails.address }}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">联系人：</span>
                    <span class="itemText">{{ orderDetails.contactsName }}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">联系电话：</span>
                    <span class="itemText">{{ orderDetails.contactsPhone }}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">公司名称: </span>
                    <span class="itemText">{{ orderDetails.companyName }}</span>
                  </span>
                </p>
              </div>
            </div>
            <!-- <div class="proOrderOperate">
              <el-button type="danger" @click="showOrderDialog(orderDetails.orderId, orderDetails.commodity)">供货</el-button>
            </div> -->
          </div>
        </el-col>
        <el-col :span="24" style="height:40px;">
           <div class="imgShare">
              <div class="imgCon">
                <!-- <img src="" alt=""> -->
              </div>
              <div class="share">
               <span>分享至:</span>
               <!-- <share :config="config" class="sharea"></share> -->
              </div>
            </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="proDetails">
            <p class="proDetailsTitle">商品详情</p>
            <div class="proDetailsContent">
              <el-row :gutter="20">
                <el-col  :span="24" v-html=" orderDetails.commodityIntroduce ">

                </el-col>
              </el-row>
            
              <!-- <el-row :gutter="20">
                <el-col :span="6">
                  <p>品名：{{ orderDetails.commodityIntroduce }}</p>
                </el-col>
                <el-col :span="6">
                  <p>品级：{{ orderDetails.grade }}</p>
                </el-col>
                <el-col :span="6">
                  <p>规格：{{ orderDetails.specifications }}</p>
                </el-col>
                <el-col :span="6">
                  <p>重量：{{ orderDetails.weight }}</p>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>数量：{{ orderDetails.quantity }}</p>
                </el-col>
                <el-col :span="6">
                  <p>订货单价（含税）：{{ orderDetails.price }}</p>
                </el-col>
                <el-col :span="6">
                  <p>合计（含税）：{{ orderDetails.zjPrice }}</p>
                </el-col>
                <el-col :span="6">
                  <p>交易商名称：{{ orderDetails.benterpriseName }}</p>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>生产厂家：{{ orderDetails.manufacturer }}</p>
                </el-col>
                <el-col :span="6">
                  <p>商品品种：{{ orderDetails.variety }}</p>
                </el-col>
                <el-col :span="6">
                  <p>包装规格：{{ orderDetails.specificationsName }}</p>
                </el-col>
                <el-col :span="6">
                  <p>包装方式：{{ orderDetails.packag }}</p>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>磅差：{{ orderDetails.poundsPoor }}</p>
                </el-col>
                <el-col :span="6">
                  <p>生产日期：{{ orderDetails.outWarehousDay | DateFormateDay }}</p>
                </el-col>
                <el-col :span="6">
                  <p>是否接受议价：{{ orderDetails.issp | isspFormate }}</p>
                </el-col>
                <el-col :span="6">
                  <p>开票期限：{{ orderDetails.openTicketDay | DateFormateInt }}</p>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>交收仓库：{{ orderDetails.storageName }}</p>
                </el-col>
              </el-row> -->
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    
    <!-- 下单弹窗 -->
    <orderDialog ref="orderDialog" />
  </div>
</template>
<script>
import searchHeader from "../base/header/searchHeader";
import orderDialog from "./orderDialog";
import QrcodeVue from 'qrcode.vue'
export default {
  data() {
    return {
      codeValue:'',
      config: {
          url:'',
          source:'',
          title: '',
          img:'',
          description: '',
          sites: ['qq', 'qzone', 'weibo', 'wechat']
      },
      proCount: 1,
      orderDetails: {
        // hangOrderId: 1,
        // commodity: "商品分类",
        // grade: "国标",
        // specifications: "99.5%",
        // weight: "5千克",
        // quantity: "5",
        // price: "¥4500.00元/千克",
        // zjPrice: "22500元",
        // enterpriseName: "交易商名称",
        // manufacturer: "生产厂家",
        // variety: "商品品种",
        // specificationsName: "包装规格",
        // package: "包装方式",
        // poundsPoor: "磅差",
        // batch: "生产批号",
        // outWarehousDay: "生产日期",
        // issp: "是否接受议价",
        // openTicketDay: "开票期限",
        // firmName: "联系人",
        // firmPhone: "联系电话",
        // note: "备注",
        // icon: null,
        // image: null
      }
    };
  },
  components: {
    searchHeader,
    orderDialog,
    QrcodeVue
  },
  created() {
    
    console.log( this.$route.params.id)
    this.config.url = window.g.SHARE_PATH + sessionStorage.getItem('productCode')
    sessionStorage.setItem('productCode', this.$route.params.id)
  },
  mounted() {
    this.getOrderDetails();
  },
  methods: {
    getOrderDetails() {

      protocolFwd.param_queryCbSupplyNoteDetails.param.supplyId = this.$route.params.id;
      http
        .postFront(protocolFwd.param_queryCbSupplyNoteDetails)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.orderDetails = value;
            for (let i in this.orderDetails) {
              if (!this.orderDetails[i]&&this.orderDetails[i]!=0) {
                this.orderDetails[i] = "--";
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    showOrderDialog(id, commodity) {
      this.$refs.orderDialog.showDialog(id, commodity);
    }
  }
};
</script>
<style lang="scss" scoped>
.productionDetails {
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  margin: 10px auto;
}
.productionDetails .proInfo,
.productionDetails .proDetails {
  padding: 15px;
  background-color: #ffffff;
  /* border: 1px solid #dcdcdc; */
  box-sizing: border-box;
}
.proInfo {
  position: relative;
  overflow: hidden;
}
.imgShare{
  position: relative;
  // overflow: hidden;
  background-color:#ffffff;
}

.proInfo .proImage,.imgShare .imgCon {
  float: left;
  margin-right: 20px;
  width: 350px;
  height: 260px;
}
.imgShare .share{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.imgShare .imgCon{
  height: 100px;
}
.proInfo .proImage img {
  max-width: 100%;
  max-height: 100%;
}
.proInfo .proImage p {
  line-height: 260px;
  text-align: center;
  color: #ffffff;
  font-size: 26px;
}
.proInfo .proContent {
  line-height: 30px;
}
.proInfo .proContent .commodity {
  font-size: 16px;
  font-weight: 700;
}
.proInfo .proContent .desc * {
  color: #666666;
}
.proInfo .proContent .desc .item {
  margin-right: 20px;
}
.proInfo .proContent .desc .item .price,
.proInfo .proContent .desc .item .zjPrice {
  font-weight: 700;
  color: $commonThemeColor;
}
.proInfo .proOrderOperate {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.proDetails {
  margin-top: 20px;
}
.proDetails .proDetailsTitle {
  margin-bottom: 10px;
  font-size: 16px;
  color: $commonThemeColor;
}
.proDetails .proDetailsContent {
  // line-height: 35px;
  // color: #666666;
}
</style>

